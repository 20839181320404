<template>
  <div>
    <admin-title
      title="Statistics"
      class="mb-n5"
    ></admin-title>

    <v-tabs
      v-model="tab"
      color="primary"
      slider-color="primary"
      class="request-tabs"
    >
      <v-tab :to="{ name: 'UsersStats' }" exact>
        <span class="slider-title">Users</span>
      </v-tab>
      <v-tab :to="{ name: 'RequestStats' }">
        <span class="slider-title">Requests</span>
      </v-tab>
    </v-tabs>

    <div class="mt-5">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      tab: null,
    }
  }
}
</script>